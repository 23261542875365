(function( $, window, document, undefined ) {
  'use strict';

  var initCarousel = function() {
    $( '#slides [class^=owl-]' ).each(function() {
      $( this ).addClass(function() {
        return this.className.replace( 'owl', 'carousel' );
      });
    });
    $( '#slides .owl-controls' ).appendTo( '#slides .carousel-controls' );
  };

  $( '.slide' ).each(function() {
    var $slide = $( this );
    var url = $slide.find( '.slide-image' ).attr( 'src' );
    $slide.css( 'background-image', 'url(' + url + ')' );
  });

  $( '#slides .carousel' ).owlCarousel({
		singleItem: true,
		pagination: false,
		navigation: true,
    slideSpeed: 900,
    autoPlay: 5000,
    afterAction: initCarousel
	});

})( window.jQuery || window.Zepto, window, document );

(function( $, window, document, undefined ) {
  'use strict';

  $( '.teaser-item' ).each(function() {
    var $this = $( this );
    var url = $this.find( 'a.more' ).attr( 'href' );
    $this.css( 'cursor', 'pointer' ).on('click', function() {
      location.href= url;
    });
//   this.style.cursor = 'pointer';
//   this.onclick = location.href = url;
  });

})( window.jQuery || window.Zepto, window, document );

(function( $, window, document, undefined ) {
  'use strict';

  function scrollTo( $element, callback ) {
  	if ( 'string' === typeof $element ) {
  		$element = $( $element );
  	}
  	if ( ! $element.length ) {
  		return false;
  	}
  	$( 'html,body' ).animate({
  		scrollTop: $element.offset().top - 512
  	}, 900, 'swing', function() {
  		if ( 'function' === typeof callback ) {
  			callback();
  		}
  	});
  	return true;
  }

	$( 'a[href^="#"]' ).on('click', function( e ) {
		e.preventDefault();
		scrollTo( this.hash );
	});

})( window.jQuery || window.Zepto, window, document );

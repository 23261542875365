(function( $, window, document, undefined ) {
  'use strict';

  var $gateways = $( '.payment-item' );

  $gateways.each(function() {
    var
      $gateway = $( this ),
      $selector = $gateway.find( '.form-element-radio' )
    ;
    if ( $selector.is( ':checked' ) ) {
      $( this ).addClass( 'active' );
      displayTotal();
    }
    $selector.change(function() {
      $gateways.removeClass( 'active' );
      if ( $( this ).is( ':checked' ) ) {
        $gateway.addClass( 'active' );
      }
      displayTotal();
    });
  });

  var $tickets = $( '.ticket-item' );

  $tickets.each(function() {
    var
      $ticket = $( this ),
      $selector = $ticket.find( '.form-element-radio, .form-element-checkbox' ),
      id = $selector.attr( 'data-ticket-id' ),
      group = $selector.attr( 'data-ticket-group' ),
      quantity = $selector.attr( 'data-ticket-quantity' )
    ;
    if ( $selector.is( ':checked' ) ) {
      $( this ).addClass( 'active' );
      displayTotal();
    }
    $selector.change(function() {
      $( 'input[data-ticket-group="' + group + '"]' ).val( 0 );
      $tickets.each(function() {
        if ( $( this ).find( 'input[data-ticket-group="' + group + '"]' ).length ) {
          $( this ).removeClass( 'active' );
        }
      });
      if ( $( this ).is( ':checked' ) ) {
        $ticket.addClass( 'active' );
        $( '#ticket-' + id ).val( quantity );
      }
      displayTotal();
    });
  });

  function formatPrice( number, tsep, dsep ) {
    var
      mantissaWithDecimals = ( number.toFixed( 2 ) + '' ).split( '.' ),
      mantissa = mantissaWithDecimals[0],
      decimals = mantissaWithDecimals.length > 1 ? dsep + mantissaWithDecimals[1] : '',
      rgx = /(\d+)(\d{3})/
    ;
    while (rgx.test( mantissa )) {
      mantissa = mantissa.replace( rgx, '$1' + tsep + '$2' );
    }
    return mantissa + decimals;
  }

  function displayTotal() {
    var
      $total = $( '#total' ),
      amount = 0,
      tsep = $total.attr( 'data-tsep' ),
      dsep = $total.attr( 'data-dsep' )
    ;
    $( '.ticket-items' ).find( '.form-element-radio:checked, .form-element-checkbox:checked' ).each(function() {
      amount += parseFloat( $( this ).attr( 'data-ticket-price' ), 10 );
    });
    $total.find( '.total-amount' ).text( formatPrice( amount, tsep, dsep ) );
    $total.toggleClass( 'active', amount > 0 );
  }

})( window.jQuery || window.Zepto, window, document );

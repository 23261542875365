(function( $, window, document, undefined ) {
  'use strict';

  var
    $window = $( window ),
    $navigation = $( '#navigation' ),
    scrolling = false,
    distance = $navigation.offset().top
  ;

  $window.scroll(function (event) {
    scrolling = true;
  });

  setInterval(function() {
    if ( scrolling ) {
      scrolling = false;
      if ( $window.scrollTop() >= distance ) {
        $( 'html' ).addClass('sticky-navigation');
      } else {
        $( 'html' ).removeClass('sticky-navigation');
      }
    }
  }, 300);

})( window.jQuery || window.Zepto, window, document );

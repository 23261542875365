(function( $, window, document, undefined ) {
  'use strict';

  var $items = $( '.topic-item' );

  $items.each(function() {
    var count = document.createElement( 'span' );
    count.className = 'topic-count';
    count.innerHTML = $( this ).attr( 'data-count' );
    this.style.backgroundColor = $( this ).attr( 'data-color' );
    this.appendChild( count );
  });

})( window.jQuery || window.Zepto, window, document );

(function( $, window, document, undefined ) {
  'use strict';

  $( '.schedule-item' ).each(function() {
    var color = $( this ).attr( 'data-color' );
    if ( color.length ) {
      this.style.borderColor = color;
    }
  });

})( window.jQuery || window.Zepto, window, document );

(function( $, window, document, undefined ) {
  'use strict';

  $( '.cta' ).each(function() {
    var $cta = $( this );
    var current = $cta.css( 'background-image' );
    var url = $cta.find( '.cta-image' ).attr( 'src' );
    if ( 'undefined' === typeof url ) {
      return true;
    }
    $cta.css( 'background-image', current + ', url(' + url + ')' );
  });

})( window.jQuery || window.Zepto, window, document );

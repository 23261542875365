(function( $, window, document, undefined ) {
  'use strict';

  var $viewport = $( 'html, body' );
  var $html = $( 'html' );

  $( '.shortcut[href="#navigation"]' ).on('click', function( e ) {
		e.preventDefault();
    $html.toggleClass( 'menu-active' );
    if ( $html.hasClass( 'menu-active' ) ) {
      $viewport.css( 'overflow', 'hidden' );
    } else {
      $viewport.css( 'overflow', 'visible' );
    }
  });

  setInterval(function() {
    $( window ).resize(function() {
      if ( $viewport.width() > 768 ) {
        $html.removeClass( 'menu-active' );
        $viewport.css( 'overflow', 'visible' );
      }
    });
  }, 300 );


})( window.jQuery || window.Zepto, window, document );
